import { Window } from '../../context';
// import { initializeMonitoring } from '../../monitoring';
import { initiate } from './runtime';

declare const window: Window;

const initiateScripts = (): void => {
    if (window.OMNISEND_FORMS_LOADED !== undefined) {
        return;
    }

    window.OMNISEND_FORMS_LOADED = true;

    // initializeMonitoring();

    initiate();
};

initiateScripts();
