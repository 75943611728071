import { UrlTargetingRule } from '../../../../types';
import { InshopFormData } from '../../types';

const removeUrlProtocol = (url: string): string => url.replace(/^(https?:\/\/)?(www\.)?/, '');
const removeTrailingSlash = (url: string): string => url.replace(/\/$/, '');

const processUrlTargetingRule = (urlTargetingRule: UrlTargetingRule): UrlTargetingRule => {
    return {
        ...urlTargetingRule,
        value: removeUrlProtocol(urlTargetingRule.value)
    };
};

const isExactUrlMatched = (shopUrl: string, ruleUrl: string): boolean => removeTrailingSlash(shopUrl) === removeTrailingSlash(ruleUrl);

export const checkUrl = (form: InshopFormData): boolean => {
    const urlTargeting = form.targeting?.url;

    if (!urlTargeting) return true;

    const { includes = [], excludes = [] } = urlTargeting;

    const processedUrlTargeting = {
        ...urlTargeting,
        includes: includes.map(processUrlTargetingRule),
        excludes: excludes.map(processUrlTargetingRule)
    };

    const currentUrl = removeUrlProtocol(decodeURI(window.location.href));

    const isIncluded =
        !processedUrlTargeting.includes.length ||
        processedUrlTargeting.includes.some((rule) =>
            rule.type === 'exact' ? isExactUrlMatched(currentUrl, rule.value) : currentUrl.includes(rule.value)
        );

    const isNotExcluded =
        !processedUrlTargeting.excludes.length ||
        processedUrlTargeting.excludes.every((rule) =>
            rule.type === 'exact' ? !isExactUrlMatched(currentUrl, rule.value) : !currentUrl.includes(rule.value)
        );

    return isIncluded && isNotExcluded;
};
