import { getContext } from '../../../context';
import { AbSetup } from '../types';

export const getAbSetups = async (): Promise<AbSetup[]> => {
    const { brand, forms } = getContext();
    const brandID = brand.getBrandID();

    if (!brandID) {
        return Promise.resolve([]);
    }

    try {
        const abSetupsResponse = await fetch(forms.getAbSetupsLoadEndpoint(brandID));
        const responseJson = await abSetupsResponse.json();
        return responseJson;
    } catch (e) {
        return [];
    }
};
