import { getActivityInformation } from './activity-monitoring';
import { getContext } from '../../../context';
import { AudienceType, DeviceType } from '../../../types';
import { getFormClosedCookie, getFormFilledCookie, getFormTeaserClosedCookie } from '../../../utils';
import { InshopFormData } from '../types';
import { checkBackInStockTargeting } from '../back-in-stock-targeting';

const HTTP_PROTOCOL = {
    http: 'http://',
    https: 'https://'
};

const transformToHttpsProtocol = (url: string): string => url.replace(HTTP_PROTOCOL.http, HTTP_PROTOCOL.https);

function isDefined<T>(x: T | undefined): x is T {
    return typeof x !== 'undefined';
}

function getFormClosedTime(form: InshopFormData): number {
    const closedAt = getFormClosedCookie(form.mainFormId || form.id);

    if (!closedAt) return 0;

    return new Date(closedAt).getTime();
}

function getFormTeaserClosedTime(form: InshopFormData): number {
    const closedAt = getFormTeaserClosedCookie(form.mainFormId || form.id);

    if (!closedAt) return 0;

    return new Date(closedAt).getTime();
}

function checkUrlMatch(form: InshopFormData): boolean {
    const urlMatching = form.targeting?.urlMatch;

    if (!urlMatching) return true;

    const { includes = [], excludes = [] } = urlMatching;

    const url = transformToHttpsProtocol(decodeURI(window.location.href));
    const isIncluded = !includes.length || includes.map(transformToHttpsProtocol).some((segment) => url.includes(segment));
    const isNotExcluded = !excludes.length || excludes.map(transformToHttpsProtocol).every((segment) => !url.includes(segment));
    return isIncluded && isNotExcluded;
}

function checkDisplay(form: InshopFormData): boolean {
    const display = form.targeting?.display;

    if (!display) return true;
    if (!Object.keys(display).length) return true;

    const { scrolledPercent, loadTime, pageViewCount, triedToClose } = getActivityInformation();

    const { afterScrollDown, afterTime, afterViewedPageCount, onExit } = display;

    const isScrolledDown = (): boolean => isDefined(afterScrollDown) && afterScrollDown <= scrolledPercent;
    const hasTimeElapsed = (): boolean => isDefined(afterTime) && loadTime + afterTime <= Date.now();
    const pageViewsReached = (): boolean => isDefined(afterViewedPageCount) && pageViewCount >= afterViewedPageCount;
    const triedToCloseWindow = (): boolean => isDefined(onExit) && onExit === triedToClose;

    return [isScrolledDown, hasTimeElapsed, pageViewsReached, triedToCloseWindow].some((rule) => rule());
}

function checkFrequency(form: InshopFormData): boolean {
    if (form.targeting?.backInStock && !getActivityInformation().isClosedFormInSession(form.id)) {
        return true;
    }

    const frequency = form.targeting?.frequency;
    const closedAt = getFormClosedTime(form);

    if (!frequency) return !closedAt;

    return closedAt + frequency <= Date.now();
}

function checkTeaserFrequency(form: InshopFormData): boolean {
    if (!form.teaser?.useCloseButton) {
        return true;
    }

    if (form.targeting?.backInStock && !getActivityInformation().isClosedFormTeaserInSession(form.id)) {
        return true;
    }

    const frequency = form.targeting?.frequency;
    const closedAt = getFormTeaserClosedTime(form);

    if (!frequency) return !closedAt;

    return closedAt + frequency <= Date.now();
}

export function checkIsFormNotFilled(form: InshopFormData): boolean {
    if (form.targeting?.backInStock) {
        return true;
    }

    return !getFormFilledCookie(form.mainFormId || form.id);
}

export function checkIsFormNotClosed(form: InshopFormData): boolean {
    return !getFormClosedCookie(form.mainFormId || form.id);
}

const checkTeaserSettingsTargeting = (form: InshopFormData): boolean => {
    const formIsNotClosed = form.targeting?.backInStock
        ? !getActivityInformation().isClosedFormInSession(form.id)
        : checkIsFormNotClosed(form);

    const formNotClosedWithBeforeTeaser = form.teaser?.showBeforeOpen && formIsNotClosed;
    const formClosedWithAfterTeaser = form.teaser?.showAfterClosing && !formIsNotClosed;
    const teaserAlwaysVisible = form.teaser?.showBeforeOpen && form.teaser?.showAfterClosing;

    return formNotClosedWithBeforeTeaser || formClosedWithAfterTeaser || teaserAlwaysVisible;
};

const checkAudienceTargeting = (form: InshopFormData): boolean => {
    const audienceType = form.targeting?.audience?.type;

    if (!audienceType) return true;

    if (audienceType === AudienceType.subscribers) {
        return getContext().user.isSubscriber();
    }
    if (audienceType === AudienceType.notSubscribers) {
        return !getContext().user.isSubscriber();
    }

    return true;
};

export function evaluateFormTeaserTargeting(form: InshopFormData): boolean {
    return [
        checkUrlMatch,
        checkDisplay,
        checkIsFormNotFilled,
        checkTeaserFrequency,
        checkTeaserSettingsTargeting,
        checkBackInStockTargeting,
        checkAudienceTargeting
    ].every((rule) => rule(form));
}

export function evaluateFormTargeting(form: InshopFormData): boolean {
    return [checkUrlMatch, checkDisplay, checkIsFormNotFilled, checkFrequency, checkBackInStockTargeting, checkAudienceTargeting].every(
        (rule) => rule(form)
    );
}

export function isDeviceSupported(formDeviceType: DeviceType): boolean {
    if (!formDeviceType) {
        return true;
    }

    const { user } = getContext();
    const userDeviceType = user.getDeviceType();

    return userDeviceType === DeviceType.mobile ? formDeviceType === DeviceType.mobile : formDeviceType !== DeviceType.mobile;
}
