import { UAParser } from 'ua-parser-js';
import { CONTACT_ID_COOKIE } from './constants';
import { FORMS_API_HOST } from './environment-constants';
import { DeviceType, FormData } from './types';
import { getHoursTimestamp, omitEmptyValues } from './utils';

declare const window: Window;

export type Window = {
    _omnisend: OmnisendContext;
    location: {
        href: string;
        pathname: string;
        protocol: string;
        hostname: string;
    };
    document: {
        title: string;
    };
    ShopifyAnalytics?: {
        meta?: {
            page?: {
                pageType?: string;
            };
            selectedVariantId?: string;
        };
    };
    Shopify?: {
        designMode: boolean;
    };
    OMNISEND_FORMS_LOADED?: boolean;
};

export type OmnisendContext = {
    cookies: {
        set: (cookieName: string, value: string, expires: number) => void;
        get: (cookieName: string) => string | undefined;
    };
    brandSettings: {
        brandID: string;
        brandData?: BrandData;
    };
    params: {
        getQuery: () => Record<string, string>;
    };
    getProductInfo: () => ProductInfo;
    version: string;
    isWindowClear?: boolean;
};

export type BrandData = {
    platformName?: string;
};

export type ProductInfo = {
    productID?: string;
    variantID?: string;
    status?: 'inStock' | 'outOfStock';
};

export type CookieOptions = {
    expiration?: number;
    sessionOnly?: boolean;
};

type BaseTrackingParams = {
    timestamp: string;
    brandID: string;
    contactID: string;
    pageTitle: string;
    pageURL: string;
    isMobile: boolean;
    v: string;
};

type FormTrackingParams = BaseTrackingParams & {
    mainFormID?: string;
    abSetupID?: string;
    formID: string;
};

type ErrorTrackingParams = BaseTrackingParams & {
    statusCode: string;
};

type TrackingParams = FormTrackingParams | ErrorTrackingParams;

const userDeviceType = new UAParser().getDevice().type as DeviceType;

const getScriptsVersion = (): string => getOmnisendContext()?.version || getHoursTimestamp();

const buildTrackingQueryParams = (trackingParams: TrackingParams): string =>
    new URLSearchParams(omitEmptyValues(trackingParams) as Record<string, string>).toString();

const getBaseTrackingParams = (): BaseTrackingParams => {
    const { brand, user, cookies, navigation } = getContext();

    return {
        timestamp: new Date().getTime().toString(),
        brandID: brand.getBrandID(),
        contactID: cookies.get(CONTACT_ID_COOKIE),
        pageTitle: navigation.getPageTitle(),
        pageURL: navigation.getPageUrl(),
        isMobile: user.getDeviceType() === DeviceType.mobile,
        v: getScriptsVersion()
    };
};

const buildTrackingParams = (form: FormData): string => {
    const trackingParams: FormTrackingParams = {
        ...getBaseTrackingParams(),
        formID: form.id,
        mainFormID: form?.mainFormId,
        abSetupID: form?.abSetupId
    };

    return buildTrackingQueryParams(trackingParams);
};

export type AppType = 'landing-page' | 'inshop-form';

export type MonitoringContext = {
    brandId: string;
    scriptsVersion: string;
    appType: AppType;
};

export type Cookies = {
    set: (name: string, value: string, options?: CookieOptions) => void;
    get: (name: string) => string | undefined;
};

export type Context = {
    brand: {
        getBrandID: () => string | undefined;
    };
    forms: {
        checkIfWindowIsClear: () => boolean;
        setWindowClearance: (isClear: boolean) => void;
        getApiHost: () => string;
        getFormsLoadEndpoint: (brandID: string) => string;
        getFormLoadEndpoint: (brandID: string, formID: string) => string;
        getFormsSubscribeEndpoint: () => string;
        getFormsBackInStockEndpoint: () => string;
        getTrackViewEndpoint: (form: FormData) => string;
        getTrackAddedInvalidEmbeddedFormEndpoint: (form: FormData) => string;
        getTrackInteractionEndpoint: (form: FormData) => string;
        getAbSetupsLoadEndpoint: (brandID: string) => string;
    };
    navigation: {
        redirect: (url: string) => void;
        redirectWithContactID: (url: string) => void;
        getPageTitle: () => string;
        getPageUrl: () => string;
        getPageBaseUrl: () => string;
        getQueryParams: () => Record<string, string>;
        isVisitorFromEmail: () => boolean;
    };
    cookies: Cookies;
    user: {
        getDeviceType: () => DeviceType;
        isSubscriber: () => boolean;
    };
    shopify: {
        isDesignMode: () => boolean;
    };
    monitoring: {
        getMonitoringContext: (appType: AppType) => MonitoringContext;
    };
};

const getBrandIDFromUrl = (): string => {
    const paths = window.location.pathname.split('/');
    return paths[paths.length - 2];
};

export const getOmnisendContext = (): OmnisendContext => {
    return (window._omnisend || {}) as OmnisendContext;
};

const redirect = (url: string): void => {
    window.location.href = url;
};

let context: Context;

const getQueryParams = (): Record<string, string> => {
    const params = window._omnisend?.params;

    if (!params) {
        return {};
    }

    return params.getQuery();
};

// Isolating context a bit to prevent direct window object manipulation
export function createContext(cookies: Cookies): Context {
    const formsApiHost = FORMS_API_HOST;
    const version = window._omnisend?.version || getHoursTimestamp();
    const userIsSubscriber = !!cookies.get(CONTACT_ID_COOKIE);

    const getBrandID = (): string => getOmnisendContext()?.brandSettings?.brandID || getBrandIDFromUrl();

    context = {
        brand: {
            getBrandID
        },
        forms: {
            checkIfWindowIsClear: () => {
                const isWindowClear = getOmnisendContext().isWindowClear;
                return isWindowClear || isWindowClear === undefined;
            },
            setWindowClearance: (isClear) => {
                if (!window._omnisend) {
                    return;
                }

                window._omnisend.isWindowClear = isClear;
            },
            getApiHost: () => formsApiHost,
            getFormsLoadEndpoint: (brandID: string): string =>
                `${formsApiHost}REST/forms/v1/renderedForms?v=${version}&brandID=${brandID}&displayType=popup,embedded,flyout`,
            getFormLoadEndpoint: (brandID: string, formID: string): string =>
                `${formsApiHost}REST/forms/v1/renderedForms/${formID}?brandID=${brandID}`,
            getFormsSubscribeEndpoint: (): string => `${formsApiHost}REST/forms/v2/subscribe`,
            getFormsBackInStockEndpoint: (): string => `${formsApiHost}REST/forms/v2/backInStockNotify`,
            getTrackViewEndpoint: (form: FormData): string => `${formsApiHost}REST/forms/v2/track/view?${buildTrackingParams(form)}`,
            getTrackAddedInvalidEmbeddedFormEndpoint: (form: FormData): string =>
                `${formsApiHost}REST/forms/v2/track/embeddedInForm?${buildTrackingParams(form)}`,
            getTrackInteractionEndpoint: (form: FormData): string =>
                `${formsApiHost}REST/forms/v2/track/interaction?${buildTrackingParams(form)}`,
            getAbSetupsLoadEndpoint: (brandID: string): string => `${formsApiHost}REST/forms/v1/abSetups?v=${version}&brandID=${brandID}`
        },
        navigation: {
            redirect,
            redirectWithContactID: (url) => {
                const contactIDCookie = cookies.get(CONTACT_ID_COOKIE);

                if (!contactIDCookie) {
                    redirect(url);

                    return;
                }

                const fullUrl = new URL(url);
                fullUrl.searchParams.set('omnisendContactID', contactIDCookie);
                redirect(fullUrl.toString());
            },
            getPageTitle: () => window.document.title,
            getPageUrl: () => window.location.href,
            getPageBaseUrl: () => `${window.location.protocol}//${window.location.hostname}`,
            getQueryParams,
            isVisitorFromEmail: () => !!getQueryParams()[CONTACT_ID_COOKIE]
        },
        cookies: {
            get: cookies.get,
            set: cookies.set
        },
        user: {
            getDeviceType: () => userDeviceType,
            isSubscriber: () => userIsSubscriber
        },
        shopify: {
            isDesignMode: () => !!window?.Shopify?.designMode
        },
        monitoring: {
            getMonitoringContext: (appType: AppType) => ({
                brandId: getBrandID(),
                scriptsVersion: getScriptsVersion(),
                appType
            })
        }
    };

    return context;
}

export function getContext(): Context {
    if (!context) {
        throw 'Application context is not initialized';
    }
    return context;
}
