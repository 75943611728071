import { CONTACT_ID_COOKIE } from '../../../../constants';
import { SourceTargetingRuleType } from '../../../../types';
import { InshopFormData } from '../../types';

const hasQueryParams = (url: string, queryParams: string[]): boolean => {
    const searchParams = new URL(url).searchParams;
    return queryParams.some((queryParam) => searchParams.has(queryParam));
};

const hasGoogleAdsParams = (url: string): boolean => hasQueryParams(url, ['gclid', 'gad_source']);
const hasBingAdsParams = (url: string): boolean => hasQueryParams(url, ['msclkid']);
const hasOmnisendCommunicationParams = (url: string): boolean => hasQueryParams(url, [CONTACT_ID_COOKIE]);

const hasMarketingParams = (url: string): boolean =>
    hasGoogleAdsParams(url) || hasBingAdsParams(url) || hasOmnisendCommunicationParams(url);

const isReferrerGoogle = (referrer: string): boolean => referrer.includes('google.com');
const isReferrerYahoo = (referrer: string): boolean => referrer.includes('yahoo.com');
const isReferrerBing = (referrer: string): boolean => referrer.includes('bing.com');
const isReferrerFacebook = (referrer: string): boolean => referrer.includes('facebook.com');
const isReferrerInstagram = (referrer: string): boolean => referrer.includes('instagram.com');
const isDirect = (referrer: string): boolean => referrer === '';
const isOrganic = (referrer: string): boolean => isReferrerGoogle(referrer) || isReferrerBing(referrer) || isReferrerYahoo(referrer);

const sourceCheckMap: Record<SourceTargetingRuleType, (currentUrl: string, referrer: string) => boolean> = {
    googleAds: (currentUrl) => hasGoogleAdsParams(currentUrl),
    organic: (currentUrl, referrer) => isOrganic(referrer) && !hasMarketingParams(currentUrl),
    direct: (currentUrl, referrer) => isDirect(referrer) && !hasMarketingParams(currentUrl),
    facebook: (_, referrer) => isReferrerFacebook(referrer),
    instagram: (_, referrer) => isReferrerInstagram(referrer),
    omnisendCommunication: (currentUrl) => hasOmnisendCommunicationParams(currentUrl)
};

export const checkSource = (form: InshopFormData): boolean => {
    const sourceTargeting = form.targeting?.source;

    if (!sourceTargeting) return true;

    const { includes = [], excludes = [] } = sourceTargeting;

    const currentUrl = window.location.href;
    const referrer = document.referrer;

    if (includes.length) {
        return includes.some((rule) => sourceCheckMap[rule]?.(currentUrl, referrer));
    }

    if (excludes.length) {
        return excludes.every((rule) => !sourceCheckMap[rule]?.(currentUrl, referrer));
    }

    return true;
};
