import { getContext } from '../../../../context';
import { getFormContainerElement, getFormTeaserButtonElement } from '../../../../elements';
import { dispatchCloseEvent } from '../../../../event-dispatchers';
import { resetFormInteracted } from '../../../../tracking';
import { hideElement, markFormClosed } from '../../../../utils';
import { setFormClosedInSession } from '../activity-monitoring';
import { showAvailableFormsTeasers } from '../form-teaser';

export const closeAction = (formID: string, mainFormId?: string): void => {
    const formContainerElement = getFormContainerElement({ parentElement: document.body, formID });
    hideElement(formContainerElement);

    getContext().forms.setWindowClearance(true);
    markFormClosed(mainFormId || formID);
    setFormClosedInSession(formID);
    resetFormInteracted(formID);
    dispatchCloseEvent({ formID });

    showAvailableFormsTeasers();
    const teaserButton = getFormTeaserButtonElement({ parentElement: document.body, formID });
    teaserButton?.focus();
};
