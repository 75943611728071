import { SourceTargetingRuleType } from '../../../../types';
import { InshopFormData } from '../../types';

const hasGoogleAdsParams = (url: string): boolean => {
    const urlParams = new URL(url).searchParams;
    return urlParams.has('gclid') || urlParams.has('gad_source');
};
const hasBingAdsParams = (url: string): boolean => {
    const urlParams = new URL(url).searchParams;
    return urlParams.has('msclkid');
};
const isReferrerGoogle = (referrer: string): boolean => referrer.includes('google.com');
const isReferrerYahoo = (referrer: string): boolean => referrer.includes('yahoo.com');
const isReferrerBing = (referrer: string): boolean => referrer.includes('bing.com');
const isReferrerFacebook = (referrer: string): boolean => referrer.includes('facebook.com');
const isReferrerInstagram = (referrer: string): boolean => referrer.includes('instagram.com');
const isDirect = (referrer: string): boolean => referrer === '';

const sourceCheckMap: Record<SourceTargetingRuleType, (currentUrl: string, referrer: string) => boolean> = {
    googleAds: (currentUrl) => hasGoogleAdsParams(currentUrl),
    organic: (currentUrl, referrer) =>
        !hasGoogleAdsParams(currentUrl) &&
        !hasBingAdsParams(currentUrl) &&
        (isReferrerGoogle(referrer) || isReferrerBing(referrer) || isReferrerYahoo(referrer)),
    direct: (_, referrer) => isDirect(referrer),
    facebook: (_, referrer) => isReferrerFacebook(referrer),
    instagram: (_, referrer) => isReferrerInstagram(referrer)
};

export const checkSource = (form: InshopFormData): boolean => {
    const sourceTargeting = form.targeting?.source;

    if (!sourceTargeting) return true;

    const { includes = [] } = sourceTargeting;
    const currentUrl = window.location.href;
    const referrer = document.referrer;

    return includes.some((rule) => sourceCheckMap[rule]?.(currentUrl, referrer));
};
