import { InshopFormData } from '../types';
import { EmbeddedForm } from './types';
import { createElement } from '../inshop-form';
import { getEmbeddedFormContainerSelector, getSubmitFormSelector } from '../../../elements';
import { hideElement, isVisibleElement, showElement } from '../../../utils';
import { trackAddedInvalidEmbeddedForm, trackView } from '../../../tracking';
import { attachEventListeners } from './event-listeners';
import { createForm } from '../../../form';
import { ActionType } from '../../../types';
import { initCountdownTimer } from '../../../components/countdown-timer';
import { dispatchStepViewEvent, dispatchViewEvent } from '../../../event-dispatchers';

export function getEmbeddedFormContainer(formId: string): Element {
    return document.querySelector(getEmbeddedFormContainerSelector(formId));
}

export function createEmbeddedForm(formData: InshopFormData): EmbeddedForm {
    const element = createElement(formData);
    const baseForm = createForm(formData, element);

    let formSetupCompleted = false;

    const renderForm = (): void => {
        const embeddedFormContainer = getEmbeddedFormContainer(formData.id);
        embeddedFormContainer.appendChild(element);
        element.innerHTML = formData.html;
    };

    const showForm = (): void => {
        if (!formSetupCompleted) {
            renderForm();
            attachEventListeners(baseForm, element);
            trackWronglyEmbeddedSubmitForm(formData);

            formSetupCompleted = true;
        }

        showElement(getEmbeddedFormContainer(formData.id));
        initCountdownTimer(element, formData);
        trackView(formData);
        dispatchViewEvent({ formID: formData.id });
        if (formData.steps?.length > 0) {
            dispatchStepViewEvent({ formID: formData.id, step: '1' });
        }
    };

    const hideForm = (): void => {
        hideElement(getEmbeddedFormContainer(formData.id));
    };

    const isFormVisible = (): boolean => {
        const embeddedFormContainer = getEmbeddedFormContainer(formData.id);
        return isVisibleElement(embeddedFormContainer);
    };

    const trackWronglyEmbeddedSubmitForm = (formData: InshopFormData): void => {
        const { id: formID, actions } = formData;
        const isSubmitAction = actions.find((action) => action.type === ActionType.submit);

        if (!isSubmitAction) {
            return;
        }

        const formContainer: HTMLElement = element.querySelector(getSubmitFormSelector(formID, ''));
        if (!formContainer) {
            trackAddedInvalidEmbeddedForm(formData);
        }
    };

    return {
        base: baseForm,
        data: formData,
        isFormVisible: isFormVisible,
        show: showForm,
        hide: hideForm
    };
}
