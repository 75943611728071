import { InshopFormData } from '../../types';

const getUtmParams = (url: string): Record<string, string> => {
    const urlParams = new URLSearchParams(new URL(url).search);
    const utmParams: Record<string, string> = {};

    ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach((param) => {
        if (urlParams.has(param)) {
            utmParams[param] = urlParams.get(param);
        }
    });

    return utmParams;
};

const utmParamsMap = {
    id: 'utm_id',
    source: 'utm_source',
    medium: 'utm_medium',
    campaign: 'utm_campaign',
    term: 'utm_term',
    content: 'utm_content'
};

export const checkUtm = (form: InshopFormData): boolean => {
    const utmTargeting = form.targeting?.utm;

    if (!utmTargeting) return true;

    const { includes = [] } = utmTargeting;
    const currentUrl = window.location.href;

    const utmParams = getUtmParams(currentUrl);

    const isIncluded =
        !includes.length ||
        includes.every((rule) => {
            const utmParamKey = utmParamsMap[rule.type];
            return utmParams[utmParamKey] === rule.value;
        });

    return isIncluded;
};
