import { getActivityInformation } from '../activity-monitoring';
import { getContext } from '../../../../context';
import { AudienceType, DeviceType } from '../../../../types';
import { getFormClosedCookie, getFormFilledCookie, getFormTeaserClosedCookie } from '../../../../utils';
import { InshopFormData } from '../../types';
import { checkBackInStockTargeting } from '../../back-in-stock-targeting';
import { checkUrl } from './url';
import { checkUtm } from './utm';
import { checkSource } from './source';

const checkAudienceTargeting = (form: InshopFormData): boolean => {
    const audienceType = form.targeting?.audience?.type;

    if (!audienceType) return true;

    if (audienceType === AudienceType.subscribers) {
        return getContext().user.isSubscriber();
    }
    if (audienceType === AudienceType.notSubscribers) {
        return !getContext().user.isSubscriber();
    }

    return true;
};

const targetingChecks: Array<(form: InshopFormData) => boolean> = [
    checkSource,
    checkUtm,
    checkUrl,
    checkDisplay,
    checkIsFormNotFilled,
    checkBackInStockTargeting,
    checkAudienceTargeting
];

function isDefined<T>(x: T | undefined): x is T {
    return typeof x !== 'undefined';
}

function getFormClosedTime(form: InshopFormData): number {
    const closedAt = getFormClosedCookie(form.mainFormId || form.id);

    if (!closedAt) return 0;

    return new Date(closedAt).getTime();
}

function getFormTeaserClosedTime(form: InshopFormData): number {
    const closedAt = getFormTeaserClosedCookie(form.mainFormId || form.id);

    if (!closedAt) return 0;

    return new Date(closedAt).getTime();
}

function checkDisplay(form: InshopFormData): boolean {
    const display = form.targeting?.display;

    if (!display) return true;
    if (!Object.keys(display).length) return true;

    const { scrolledPercent, loadTime, pageViewCount, triedToClose } = getActivityInformation();

    const { afterScrollDown, afterTime, afterViewedPageCount, onExit } = display;

    const isScrolledDown = (): boolean => isDefined(afterScrollDown) && afterScrollDown <= scrolledPercent;
    const hasTimeElapsed = (): boolean => isDefined(afterTime) && loadTime + afterTime <= Date.now();
    const pageViewsReached = (): boolean => isDefined(afterViewedPageCount) && pageViewCount >= afterViewedPageCount;
    const triedToCloseWindow = (): boolean => isDefined(onExit) && onExit === triedToClose;

    return [isScrolledDown, hasTimeElapsed, pageViewsReached, triedToCloseWindow].some((rule) => rule());
}

function checkFrequency(form: InshopFormData): boolean {
    if (form.targeting?.backInStock && !getActivityInformation().isClosedFormInSession(form.id)) {
        return true;
    }

    const frequency = form.targeting?.frequency;
    const closedAt = getFormClosedTime(form);

    if (!frequency) return !closedAt;

    return closedAt + frequency <= Date.now();
}

function checkTeaserFrequency(form: InshopFormData): boolean {
    if (!form.teaser?.useCloseButton) {
        return true;
    }

    if (form.targeting?.backInStock && !getActivityInformation().isClosedFormTeaserInSession(form.id)) {
        return true;
    }

    const frequency = form.targeting?.frequency;
    const closedAt = getFormTeaserClosedTime(form);

    if (!frequency) return !closedAt;

    return closedAt + frequency <= Date.now();
}

export function checkIsFormNotFilled(form: InshopFormData): boolean {
    if (form.targeting?.backInStock) {
        return true;
    }

    return !getFormFilledCookie(form.mainFormId || form.id);
}

export function checkIsFormNotClosed(form: InshopFormData): boolean {
    return !getFormClosedCookie(form.mainFormId || form.id);
}

const checkTeaserSettingsTargeting = (form: InshopFormData): boolean => {
    const formIsNotClosed = form.targeting?.backInStock
        ? !getActivityInformation().isClosedFormInSession(form.id)
        : checkIsFormNotClosed(form);

    const formNotClosedWithBeforeTeaser = form.teaser?.showBeforeOpen && formIsNotClosed;
    const formClosedWithAfterTeaser = form.teaser?.showAfterClosing && !formIsNotClosed;
    const teaserAlwaysVisible = form.teaser?.showBeforeOpen && form.teaser?.showAfterClosing;

    return formNotClosedWithBeforeTeaser || formClosedWithAfterTeaser || teaserAlwaysVisible;
};

export function evaluateFormTeaserTargeting(form: InshopFormData): boolean {
    return [checkTeaserFrequency, checkTeaserSettingsTargeting, ...targetingChecks].every((rule) => rule(form));
}

export function evaluateFormTargeting(form: InshopFormData): boolean {
    return [checkFrequency, ...targetingChecks].every((rule) => rule(form));
}

export function isDeviceSupported(formDeviceType: DeviceType): boolean {
    if (!formDeviceType) {
        return true;
    }

    const { user } = getContext();
    const userDeviceType = user.getDeviceType();

    return userDeviceType === DeviceType.mobile ? formDeviceType === DeviceType.mobile : formDeviceType !== DeviceType.mobile;
}
