import { DYNAMIC_CONTAINER_ID, FORMS_CONTAINER_ID } from '../../constants';
import { createContext, getContext } from '../../context';
import { setupTracking } from '../../tracking';
import { DisplayType } from '../../types';
import { initiate as initiateEmbeddedForms } from './embedded';
import { initiate as initiatePopupForms } from './popup';
import { getCookie, setCookie } from './cookies';
import { setFormsToState } from '../../state';
import { createFocusLocker } from '../../accessibility';
import { getApplicableForms } from './loader/loader';

function createFormContainer(): void {
    const omnisendContainer = document.getElementById(DYNAMIC_CONTAINER_ID);

    const formsContainer = document.createElement('div');
    formsContainer.setAttribute('id', FORMS_CONTAINER_ID);

    omnisendContainer.appendChild(formsContainer);
}

export const initiate = async (): Promise<void> => {
    createContext({
        get: getCookie,
        set: setCookie
    });

    const { shopify, navigation } = getContext();

    // setMonitoringContext(monitoring.getMonitoringContext('inshop-form'));

    const forms = await getApplicableForms();
    const popupForms = forms.filter((form) => form.displayType === DisplayType.popup || form.displayType === DisplayType.flyout);
    const embeddedForms = forms.filter((form) => form.displayType === DisplayType.embedded);

    if (!popupForms.length && !embeddedForms.length) {
        return;
    }

    createFocusLocker();
    createFormContainer();
    setupTracking();
    setFormsToState(forms);

    if (embeddedForms.length) {
        initiateEmbeddedForms(embeddedForms);
    }

    if (navigation.isVisitorFromEmail()) {
        return;
    }

    if (shopify.isDesignMode()) {
        return;
    }

    if (popupForms.length) {
        initiatePopupForms(popupForms);
    }
};
