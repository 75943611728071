import { enrichFormWithMainForm, getAbSetupFormsSelectedVersions } from '../ab-setup';
import { getAbSetups } from '../api/ab-setups';
import { evaluateServerSideTargeting, getForms } from '../api/forms';
import { InshopFormData } from '../types';

const loadForms = async (): Promise<InshopFormData[]> => {
    const forms = (await getForms()).filter((form) => !!form.html);

    const hasServerSideTargeting = forms.some((form) => !!form.targeting?.serverSideTargeting);

    if (!hasServerSideTargeting) {
        return forms;
    }

    const { formIDs } = await evaluateServerSideTargeting();
    return forms.filter((form) => formIDs.includes(form.id));
};

export const getApplicableForms = async (): Promise<InshopFormData[]> => {
    const forms = await loadForms();
    const regularForms = forms.filter((form) => !form.abSetupId);
    const abSetupForms = forms.filter((form) => !!form.abSetupId);

    if (!abSetupForms.length) {
        return regularForms;
    }

    const abSetups = await getAbSetups();
    const selectedAbSetupForms = getAbSetupFormsSelectedVersions(abSetupForms, abSetups).map((form) =>
        enrichFormWithMainForm(form, abSetups)
    );

    return [...regularForms, ...selectedAbSetupForms];
};
