import { getContext } from '../../../context';
import { evaluateFormTargeting, isDeviceSupported } from './targeting';
import { monitorActivity, onActivityChange } from './activity-monitoring';
import { createPopupForm } from './popup-form';
import { initiateFormTeasers, showAvailableFormsTeasers } from './form-teaser';
import { PopupForm } from './types';
import { InshopFormData } from '../types';

function tryToShowFormsAndTeasers(forms: PopupForm[]): void {
    const context = getContext();
    if (!context.forms.checkIfWindowIsClear()) {
        return;
    }

    showAvailableFormsTeasers();
    forms
        .filter((form) => evaluateFormTargeting(form.data))
        .forEach((form) => {
            if (form.data.teaser?.showBeforeOpen) {
                return;
            }

            form.show();
        });
}

export const initiate = async (forms: InshopFormData[]): Promise<void> => {
    const supportedForms = forms.filter((form) => isDeviceSupported(form.targeting?.device));
    if (!supportedForms.length) {
        return;
    }

    monitorActivity();

    const popupForms: PopupForm[] = supportedForms.map((data) => createPopupForm(data));

    initiateFormTeasers(popupForms);

    onActivityChange(() => tryToShowFormsAndTeasers(popupForms));
    setInterval(() => {
        tryToShowFormsAndTeasers(popupForms);
    }, 1000);
};
