import { getScriptsVersion, Window } from '../../context';
import { FORMS_HOST } from '../../environment-constants';
import { initiate } from './runtime';

declare const window: Window;

const initiateMonitoring = (): void => {
    const script = document.createElement('script');
    script.src = `${FORMS_HOST}monitoring.js?v=${getScriptsVersion()}`;
    document.head.appendChild(script);
};

const initiateScripts = (): void => {
    if (window.OMNISEND_FORMS_LOADED !== undefined) {
        return;
    }

    initiateMonitoring();

    window.OMNISEND_FORMS_LOADED = true;

    initiate();
};

initiateScripts();
