import { closeAction } from '../actions/close';
import { ActionType, ListenerOptions } from '../../../../types';
import { safeExecute } from '../../../../utils';
import { getFormBrandingSelector, getFormContentInnerSelector, getFormTeaserSelector } from '../../../../elements';

type GlobalListenerOptions = Omit<ListenerOptions, 'element'>;

const handleEscapeButton = ({ form, additionalActions }: GlobalListenerOptions): void => {
    document.addEventListener('keydown', function keydownListener(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            event.preventDefault();
            closeAction(form.data.id, form.data.mainFormId);
            additionalActions.map(safeExecute);
            document.removeEventListener('keydown', keydownListener);
        }
    });
};

const handleClickOutside = ({ form, additionalActions }: GlobalListenerOptions): void => {
    document.addEventListener('click', function outsideClickListener(event: MouseEvent) {
        const formContent = document.querySelector(getFormContentInnerSelector(form.data.id));
        const formBranding = document.querySelector(getFormBrandingSelector(form.data.id));
        const formTeaser = document.querySelector(getFormTeaserSelector(form.data.id));
        const targetElement = event.target as Element;
        const isOutside = [formContent, formBranding, formTeaser].every((element) => !element?.contains(targetElement));

        if (isOutside) {
            event.preventDefault();
            event.stopPropagation();
            closeAction(form.data.id, form.data.mainFormId);
            additionalActions.map(safeExecute);
            document.removeEventListener('click', outsideClickListener);
        }
    });
};

export const attachGlobalListeners = ({ form, additionalActions = [] }: GlobalListenerOptions): void => {
    handleEscapeButton({ form, additionalActions });

    if (form.data.actions?.find((action) => action.type === ActionType.clickOutside)) {
        handleClickOutside({ form, additionalActions });
    }
};
