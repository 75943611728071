import { FieldErrorType } from '../types';
import {
    getFormBlockErrorSelector,
    getFormBlockInputSelector,
    getFormBlockLabelSelector,
    getFormBlockRequiredErrorSelector,
    getFormContainerSelector,
    getFormErrorSelector,
    getFormFieldCheckboxSelector,
    getFormFieldRadioSelector,
    getFormFieldSelectSelector,
    getFormTeaserButtonSelector,
    getPhoneNumberPrefixSelector,
    getPromotionalFormSelector,
    getSubmitFormSelector
} from './selectors';

export * from './selectors';

type ElementSelectorOptions = {
    parentElement: HTMLElement;
    formID: string;
    stepID?: string;
};

type FormFieldElementSelectorOptions = {
    fieldID: string;
    fieldName?: string;
} & ElementSelectorOptions;

export const getFormErrorElement = ({ parentElement, formID }: ElementSelectorOptions): HTMLElement => {
    return parentElement?.querySelector(getFormErrorSelector(formID));
};

export const getFormFieldVisualElements = ({
    parentElement,
    formID,
    fieldID,
    fieldName
}: FormFieldElementSelectorOptions): HTMLElement[] => {
    let fieldInputElements: HTMLElement[] = [];
    let fieldSelectElements: HTMLElement[] = [];
    let fieldRadioElements: HTMLElement[] = [];

    parentElement.querySelectorAll<HTMLElement>(getFormBlockInputSelector(formID, fieldID)).forEach((el) => {
        fieldInputElements = [...fieldInputElements, el];
    });
    parentElement.querySelectorAll<HTMLElement>(getFormFieldSelectSelector(formID, fieldID, fieldName)).forEach((el) => {
        fieldSelectElements = [...fieldSelectElements, el];
    });
    parentElement.querySelectorAll<HTMLElement>(getFormFieldRadioSelector(formID, fieldID, fieldName)).forEach((el) => {
        fieldRadioElements = [...fieldRadioElements, el];
    });

    return [
        ...fieldInputElements,
        ...fieldSelectElements,
        ...fieldRadioElements,
        parentElement.querySelector<HTMLElement>(getPhoneNumberPrefixSelector(formID, fieldID)),
        parentElement.querySelector<HTMLElement>(getFormBlockLabelSelector(formID, fieldID))
    ].filter((element) => !!element);
};

export const getFormFieldElement = ({ parentElement, formID, fieldID, fieldName }: FormFieldElementSelectorOptions): HTMLElement =>
    parentElement.querySelector(getFormFieldCheckboxSelector(formID, fieldID)) ||
    parentElement.querySelector(getFormBlockInputSelector(formID, fieldID)) ||
    parentElement.querySelector(getFormFieldSelectSelector(formID, fieldID, fieldName)) ||
    parentElement.querySelector(getFormFieldRadioSelector(formID, fieldID, fieldName));

export const getFormFieldErrorElement = ({ parentElement, formID, fieldID }: FormFieldElementSelectorOptions): HTMLElement => {
    return parentElement.querySelector<HTMLElement>(getFormBlockErrorSelector(formID, fieldID));
};

export const getFormFieldRequiredErrorElement = ({ parentElement, formID, fieldID }: FormFieldElementSelectorOptions): HTMLElement => {
    return parentElement.querySelector<HTMLElement>(getFormBlockRequiredErrorSelector(formID, fieldID));
};

export const getFormFieldErrorElementByErrorType = (
    { parentElement, formID, fieldID }: FormFieldElementSelectorOptions,
    fieldErrorType: FieldErrorType
): HTMLElement => {
    if (fieldErrorType !== FieldErrorType.fieldInvalid && fieldErrorType !== FieldErrorType.fieldMissing) {
        return;
    }

    const isInvalidFieldErrorType = fieldErrorType === FieldErrorType.fieldInvalid;

    return isInvalidFieldErrorType
        ? getFormFieldErrorElement({ parentElement, formID, fieldID })
        : getFormFieldRequiredErrorElement({ parentElement, formID, fieldID });
};

const FOCUSABLE_ELEMENTS = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export const getFocusableElements = (parentElement: HTMLElement): HTMLElement[] => {
    return Array.from(parentElement.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS));
};

export const getFormStepElement = ({ parentElement, formID, stepID }: ElementSelectorOptions): HTMLElement => {
    return (
        parentElement.querySelector<HTMLElement>(getSubmitFormSelector(formID, stepID)) ||
        parentElement.querySelector<HTMLElement>(getPromotionalFormSelector(formID, stepID))
    );
};

export const getFormTeaserButtonElement = ({ parentElement, formID }: ElementSelectorOptions): HTMLElement => {
    return parentElement.querySelector<HTMLElement>(getFormTeaserButtonSelector(formID));
};

export const getFormContainerElement = ({ parentElement, formID }: ElementSelectorOptions): HTMLElement => {
    return parentElement.querySelector<HTMLElement>(getFormContainerSelector(formID));
};
