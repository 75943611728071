import { hideElement, isVisibleElement, showElement } from '../../utils';
import { SELECT_ATTRIBUTES, SELECT_CLASSNAMES } from './constants';
import { SelectMetadata } from './types';

export const selectOption = (option: HTMLElement, metadata: SelectMetadata, options: NodeListOf<Element>): void => {
    if (!option) {
        return;
    }

    options.forEach((option) => {
        option.classList.remove(SELECT_CLASSNAMES.selectedOption);
        option.classList.remove(SELECT_CLASSNAMES.visuallyFocusedOption);
        option.setAttribute(SELECT_ATTRIBUTES.ARIA_SELECTED, 'false');
    });

    const { formElement, selectors, additionalOptionSelectCallback } = metadata;

    option.classList.add(SELECT_CLASSNAMES.selectedOption);
    option.setAttribute(SELECT_ATTRIBUTES.ARIA_SELECTED, 'true');
    markOptionVisuallySelected(option, metadata);

    const selectedOptionValue = option.getAttribute(SELECT_ATTRIBUTES.VALUE);
    const selectedOptionText = option.querySelector(selectors.optionValue).textContent.trim();

    const select = formElement.querySelector(selectors.select);
    select.setAttribute(SELECT_ATTRIBUTES.VALUE, selectedOptionValue);

    const selectText = select.querySelector(selectors.selectText);
    const selectPlaceholder = select.querySelector(selectors.selectPlaceholder);

    selectText.textContent = selectedOptionText;

    if (!isVisibleElement(selectText)) {
        showElement(selectText);
        hideElement(selectPlaceholder);
    }

    hideOptionsContainer(metadata);
    additionalOptionSelectCallback && additionalOptionSelectCallback(option);
};

const trackClickOutside = (metadata: SelectMetadata): void => {
    const { formElement, selectors } = metadata;
    const select = formElement.querySelector(selectors.select);
    const optionsContainer = formElement.querySelector(selectors.optionsContainer);

    const outsideClickListener = (e: Event): void => {
        const targetElement = e.target as Element;
        const isOutside = !select.contains(targetElement) && !optionsContainer.contains(targetElement);

        if (isOutside) {
            hideOptionsContainer(metadata, true);
        }

        if (!isVisibleElement(optionsContainer) || isOutside) {
            document.removeEventListener('click', outsideClickListener);
        }
    };

    document.addEventListener('click', outsideClickListener);
};

export const hideOptionsContainer = (metadata: SelectMetadata, preventParentFocus?: boolean): void => {
    const { formElement, selectors } = metadata;
    const optionsContainer = formElement.querySelector(selectors.optionsContainer);
    const select = formElement.querySelector<HTMLElement>(selectors.select);

    hideElement(optionsContainer);
    select.setAttribute(SELECT_ATTRIBUTES.ARIA_EXPANDED, 'false');
    optionsContainer.setAttribute(SELECT_ATTRIBUTES.ARIA_EXPANDED, 'false');

    if (!preventParentFocus) {
        select.focus();
    }
};

export const showOptionsContainer = (metadata: SelectMetadata): void => {
    const { formElement, selectors } = metadata;
    const select = formElement.querySelector(selectors.select);
    const optionsContainer = formElement.querySelector<HTMLElement>(selectors.optionsContainer);

    select.setAttribute(SELECT_ATTRIBUTES.ARIA_EXPANDED, 'true');
    showElement(optionsContainer);
    trackClickOutside(metadata);
};

export const markOptionVisuallySelected = (option: Element, metadata: SelectMetadata): void => {
    if (!option) {
        return;
    }

    option.classList?.add(SELECT_CLASSNAMES.visuallyFocusedOption);

    const select = metadata.formElement.querySelector<HTMLElement>(metadata.selectors.select);
    const optionId = option.getAttribute('id');
    select?.setAttribute(SELECT_ATTRIBUTES.ARIA_ACTIVEDESCENDANT, optionId);
};
