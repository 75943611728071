import { CONTACT_ID_COOKIE } from '../../../constants';
import { getContext } from '../../../context';
import { DisplayType } from '../../../types';
import { InshopFormData } from '../types';

export const getForms = async (): Promise<InshopFormData[]> => {
    const { brand, forms } = getContext();
    const brandID = brand.getBrandID();

    if (!brandID) {
        return Promise.resolve([]);
    }

    try {
        const formsResponse = await fetch(forms.getFormsLoadEndpoint(brandID));
        const responseJson = await formsResponse.json();
        return responseJson;
    } catch {
        return [];
    }
};

type ServerSideTargetingResponse = {
    formIDs: string[];
};

export const evaluateServerSideTargeting = async (): Promise<ServerSideTargetingResponse> => {
    const { brand, cookies, forms } = getContext();

    try {
        const targetingResponse = await fetch(forms.getEvaluateTargetingEndpoint(), {
            method: 'POST',
            body: JSON.stringify({
                brandID: brand.getBrandID(),
                contactID: cookies.get(CONTACT_ID_COOKIE),
                displayTypes: [DisplayType.flyout, DisplayType.popup, DisplayType.embedded]
            })
        });
        const responseJson = await targetingResponse.json();
        return responseJson;
    } catch {
        return { formIDs: [] };
    }
};
