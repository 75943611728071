import { getContext } from '../../../context';
import { InshopFormData } from '../types';

export const getForms = async (): Promise<InshopFormData[]> => {
    const { brand, forms } = getContext();
    const brandID = brand.getBrandID();

    if (!brandID) {
        return Promise.resolve([]);
    }

    const formsResponse = await fetch(forms.getFormsLoadEndpoint(brandID));

    return formsResponse.json();
};
