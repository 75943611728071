import { DYNAMIC_CONTAINER_ID, FORMS_CONTAINER_ID } from '../../constants';
import { createContext, getContext } from '../../context';
import { setupTracking } from '../../tracking';
import { DisplayType } from '../../types';
import { initiate as initiateEmbeddedForms } from './embedded';
import { initiate as initiatePopupForms } from './popup';
import { getCookie, setCookie } from './cookies';
import { getForms } from './api/forms';
import { enrichFormWithMainForm, getAbSetupFormsSelectedVersions } from './ab-setup';
import { getAbSetups } from './api/ab-setups';
import { InshopFormData } from './types';
import { setFormsToState } from '../../state';
import { createFocusLocker } from '../../accessibility';

function createFormContainer(): void {
    const omnisendContainer = document.getElementById(DYNAMIC_CONTAINER_ID);

    const formsContainer = document.createElement('div');
    formsContainer.setAttribute('id', FORMS_CONTAINER_ID);

    omnisendContainer.appendChild(formsContainer);
}

const getApplicableForms = async (): Promise<InshopFormData[]> => {
    const forms = (await getForms()).filter((form) => !!form.html);

    const regularForms = forms.filter((form) => !form.abSetupId);
    const abSetupForms = forms.filter((form) => !!form.abSetupId);

    if (!abSetupForms.length) {
        return regularForms;
    }

    const abSetups = await getAbSetups();
    const selectedAbSetupForms = getAbSetupFormsSelectedVersions(abSetupForms, abSetups).map((form) =>
        enrichFormWithMainForm(form, abSetups)
    );

    return [...regularForms, ...selectedAbSetupForms];
};

export const initiate = async (): Promise<void> => {
    createContext({
        get: getCookie,
        set: setCookie
    });

    const { shopify, navigation } = getContext();

    // setMonitoringContext(monitoring.getMonitoringContext('inshop-form'));

    const forms = await getApplicableForms();
    const popupForms = forms.filter((form) => form.displayType === DisplayType.popup || form.displayType === DisplayType.flyout);
    const embeddedForms = forms.filter((form) => form.displayType === DisplayType.embedded);

    if (!popupForms.length && !embeddedForms.length) {
        return;
    }

    createFocusLocker();
    createFormContainer();
    setupTracking();
    setFormsToState(forms);

    if (embeddedForms.length) {
        initiateEmbeddedForms(embeddedForms);
    }

    if (navigation.isVisitorFromEmail()) {
        return;
    }

    if (shopify.isDesignMode()) {
        return;
    }

    if (popupForms.length) {
        initiatePopupForms(popupForms);
    }
};
